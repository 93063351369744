import React from 'react'
import './Navbar.css';

function Navbar({
    alt,
    img, 
}){
  return (
    <div>
        <div className='row_navbar'>
            <div className='navbar__hero-img-wrapper'>
                <img src={img} alt={alt} className='navbar__hero-img' />
            </div>
        </div>
    </div>
    
  )
}

export default Navbar