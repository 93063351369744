export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Antecipe já',
    headLine: 'O DINHEIRO JÁ É SEU, FALTA SÓ VOCÊ PEGAR',
    description:
      'Dinheiro na sua conta em 20 minutos',
    buttonLabel: 'Simule pelo WhatsApp',
    imgStart: '',
    img: 'images/img_6_site_fgts.png',
    alt: 'Antecipe seu FGTS',
    url:'https://api.whatsapp.com/send?phone=553599436777&text=Quero%20simular%20a%20antecipa%C3%A7%C3%A3o%20do%20meu%20FGTS'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% Seguro',
    headLine: 'Contrate online e receba o dinheiro na conta em 20 minutos',
    description:
      'Todo processo é feito pelo celular através do aplicativo do banco. Você se sentirá muito seguro ao contratar.',
    buttonLabel: 'Simule pelo WhatsApp',
    imgStart: 'start',
    img: 'images/cofre.png',
    alt: 'Vault',
    url:'https://api.whatsapp.com/send?phone=553599436777&text=Quero%20simular%20a%20antecipa%C3%A7%C3%A3o%20do%20meu%20FGTS'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Sem parcelas mensais',
    headLine: 'Isso mesmo, você realiza seu sonho sem ter que pagar parcelas mensais',
    description:
      "Ótima opção pra quem quer sair do sufoco sem compromentar mais a renda",
    buttonLabel: 'Simule pelo WhatsApp',
    imgStart: 'start',
    img: 'images/cofre_porco.png',
    alt: 'Vault',
    url:'https://api.whatsapp.com/send?phone=553599436777&text=Quero%20simular%20a%20antecipa%C3%A7%C3%A3o%20do%20meu%20FGTS'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Liberação fácil',
    headLine: 'Sem consulta SPC/SERASA',
    description:
      'Mesmo se você estiver com o restrição no nome é possível a liberação do seu FGTS.',
    buttonLabel: 'Simule pelo WhatsApp',
    imgStart: 'start',
    img: 'images/dinheiro.png',
    alt: 'Antecipe seu fgts',
    url:'https://api.whatsapp.com/send?phone=553599436777&text=Quero%20simular%20a%20antecipa%C3%A7%C3%A3o%20do%20meu%20FGTS'
  };