import './App.css';
import { RouterProvider } from "react-router-dom";
import React from "react";
import {router} from './routes/root'


function App() {
  return (
    <div className="App">
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}

export default App;
