import React from 'react';
import { Button } from './Button';
import './SecondSection.css'
import { BiTimer } from 'react-icons/bi'; 
import { GiReceiveMoney } from 'react-icons/gi'; 
import { AiOutlineCheckCircle } from 'react-icons/ai'; 
import { CiPercent } from 'react-icons/ci';

function SecondSection({
    buttonLabel,
    img, 
    alt, 
}) {
  return (
    <>
    <div className='container-SecondSection'>
          <h1 className='secondSection-text'>Vantagens</h1>

          <div className='row-SecondSection'>
         
            <div className='col-SecondSection'>
                <div className='SecondSection-item'>
                <div className='row-item-SecondSection'>
                <div className='SecondSection-img-wrapper'>
                    <div className='icon-time'>
                        <BiTimer />
                    </div>
                </div>
                <h1 className='text-SecondSection-h1'>RÁPIDO E SEGURO</h1>
                <h3 className='text-SecondSection-h2'>
                O dinheiro cai na sua conta em 20 minutos e o processo é todo feito pelo aplicativo do banco, trazendo maior segurança na contratação.
                </h3>
                </div>
                </div>
           
                
            </div>
            <div className='col-SecondSection'>
                <div className='SecondSection-item'>
                <div className='SecondSection-img-wrapper'>
                <div className='icon-dolar'>
                        <GiReceiveMoney />
                    </div>
                </div>
                <h1 className='text-SecondSection-h1'>NÃO COMPROMETE SUA RENDA MENSAL</h1>
                <h3 className='text-SecondSection-h2'>
                 Como é descontado diretamente do FGTS a antecipação do saque aniversário não gera parcelas mensais
                </h3>
                </div>
                
            </div>
            <div className='col-SecondSection'>
                <div className='SecondSection-item'>
                <div className='SecondSection-img-wrapper'>
                <div className='icon-time'>
                        <AiOutlineCheckCircle />
                    </div>
                </div>
                <h1 className='text-SecondSection-h1'>SEM CONSULTA SPC/SERASA</h1>
                <h3 className='text-SecondSection-h2'>
                Se você está com restrição no nome isso não será problema, pelo contrário uma solução pra você resolver a sua vida financeira.
                </h3>
                </div>
                
            </div>
            <div className='col-SecondSection'>
                <div className='SecondSection-item'>
                <div className='SecondSection-img-wrapper'>
                <div className='icon-time'>
                        <CiPercent />
                    </div>
                </div>
                <h1 className='text-SecondSection-h1'>TAXAS DE JUROS A PARTIR DE 1,79% a.m*</h1>
                <h3 className='text-SecondSection-h2'>
                Essa é a modalidade de crédito com uma das menores taxas do mercado.
                </h3>
                </div>
                
            </div>
        </div>
        <div className='SecondSection-botton'>
            <a href='https://api.whatsapp.com/send?phone=553599436777&text=Quero%20simular%20a%20antecipa%C3%A7%C3%A3o%20do%20meu%20FGTS'>
            <Button buttonSize='btn--wide' buttonColor='blue'>
                {buttonLabel}
            </Button>
            </a>
        </div>
      </div>
    </>
  );
}

export default SecondSection