import React from 'react'
import '../footer/Footer.css'

export default function Footer() {
  return (
    <div className="footer-root">
        <div className='footer-top'>
        TERMOS DO SITE:
        </div>
        <div className='terms'>
        A FGTS.digital é uma plataforma digital pertence à CUNHA E MARQUES LTDA ME CNPJ 13.696.271/0001-75
        que atua como correspondente bancário, seguindo as diretrizes do Banco Central do Brasil (BACEN), 
        nos termos da Resolução nº. 3.954, de 24 de fevereiro de 2011. 
        Crédito sujeito a margem consignável ou saldo de FGTS disponível no momento da contratação e às condições do convênio. 
        Valores de parcelas, prazos, taxa de juros, tarifas e CET (Custo Efetivo Total) 
        podem variar conforme o convênio e a qualquer tempo, sem necessidade de aviso prévio. 
        Os prazos das operações podem variar de 6 a 120 meses. Todas as operações de crédito têm incidência de IOF, conforme legislação vigente. 
        </div>
        <div className='copy'>
        © Copyright 2023 | FGTS.Digital
        </div>
        
    </div>
  )
}


