import React from 'react'
import HeroSection from '../../HeroSection'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../HomePage/Data'
import Navbar from '../Navbar/Navbar'
import { NavbarOne } from '../Navbar/Navbar_Data'
import SecondSection from '../../SecondSection'
import { SecondSectionObj } from '../HomePage/SecondSectionData'
import  Footer from '../footer/Footer'

function Home(){
  return (
    <>
    <div className='nav'>
      <Navbar {...NavbarOne}/>
    </div>
    <div className='hero'>
      <HeroSection {...homeObjOne} />
      <SecondSection {...SecondSectionObj}/>
      <HeroSection {...homeObjTwo} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFour} />
    </div>
    <div className='footer'>
      <Footer/>
    </div>
    
    </>
  );
}

export default Home
